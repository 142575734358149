







import { Vue, Component } from "vue-property-decorator";
import NavbarVertical from "@/components/NavbarVertical.vue";
@Component({
    components: {
        "al-navbar-vertical": NavbarVertical
    }
})
export default class UserCenter extends Vue {}
